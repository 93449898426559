<template>
	<div>
		<Preloader ref="preloader"></Preloader>

		<HeaderOther></HeaderOther>

		<section class="offer">
			<h1>{{ $t('offer.title') }}</h1>
			<ol>
				<li>
					{{ $t('offer.1') }}
					<ol>
						<li>{{ $t('offer.1_1') }}</li>
						<li>{{ $t('offer.1_2') }}</li>
						<li>{{ $t('offer.1_3') }}</li>
						<li>{{ $t('offer.1_4') }}</li>
						<li>{{ $t('offer.1_5') }}</li>
						<li>{{ $t('offer.1_6') }}</li>
						<li>{{ $t('offer.1_7') }}</li>
					</ol>
				</li>

				<li>
					{{ $t('offer.2') }}
					<ol>
						<li>{{ $t('offer.2_1') }}</li>
						<li>{{ $t('offer.2_2') }}</li>
					</ol>
				</li>

				<li>
					{{ $t('offer.3') }}
					<ol>
						<li>{{ $t('offer.3_1') }}</li>
						<li>{{ $t('offer.3_2') }}</li>
						<li>{{ $t('offer.3_3') }}</li>
					</ol>
				</li>

				<li>
					{{ $t('offer.4') }}
					<ol>
						<li>{{ $t('offer.4_1') }}</li>
					</ol>
				</li>

				<li>
					{{ $t('offer.5') }}
					<ol>
						<li>{{ $t('offer.5_1') }}</li>
						<li>{{ $t('offer.5_2') }}</li>
						<li>{{ $t('offer.5_3') }}</li>
					</ol>
				</li>

				<li>
					{{ $t('offer.6') }}
					<ol>
						<li>{{ $t('offer.6_1') }}</li>
						<li>{{ $t('offer.6_2') }}</li>
						<li>{{ $t('offer.6_3') }}</li>
					</ol>
				</li>

				<li>
					{{ $t('offer.7') }}
					<ol>
						<li>{{ $t('offer.7_1') }}</li>
						<li>{{ $t('offer.7_2') }}</li>
						<li>{{ $t('offer.7_3') }}</li>
						<li>{{ $t('offer.7_4') }}</li>
						<li>{{ $t('offer.7_5') }}</li>
						<li>{{ $t('offer.7_6') }}</li>
					</ol>
				</li>

				<li>
					{{ $t('offer.8') }}
					<p>{{ $t('offer.8_1') }}</p>
					<p>{{ $t('offer.8_2') }}</p>
					<p>{{ $t('offer.8_3') }}</p>
				</li>

				<li>
					{{ $t('offer.9') }}
					<p>{{ $t('offer.9_1') }}</p>
				</li>
			</ol>
		</section>

		<Footer></Footer>
	</div>
</template>

<script>
	import Footer from "../components/Footer";
	import Preloader from "../components/Preloader";
	import HeaderOther from "../components/HeaderOther";

	export default {
		name: "OfferPage",
		components: {Footer, Preloader, HeaderOther},
		mounted() {
			this.$refs.preloader.$el.style.display = 'none';
		}
	}
</script>

<style scoped>
	.offer {
		display: flex;
		flex-direction: column;
		align-items: center;
		color: #fff;
		margin: 5em 0 10em;
	}

	.offer h1 {
		margin-bottom: 1.2em;
	}

	.offer ol {
		list-style: none;
		padding: 0 1.2em;
		counter-reset: item;
	}

	.offer > ol {
		width: 100vw;
		padding: 0 10em;
	}

	.offer > ol * {
		user-select: text;
	}

	.offer li {
		margin: 0.4em 0;
	}

	.offer li:before {
		content: counters(item, ".") ". ";
		counter-increment: item;
	}

	.offer li p {
		margin-left: 1.2em;
	}

	@media screen and (max-width: 1324px){
		.offer > ol {
			padding: 0 2em;
		}
	}
</style>
